import React, { Component } from 'react';
import {Link} from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#current" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li><Link to={"/app"}>Home</Link></li>
            
	         
			 <li><Link to={"/statistics"}>Statistics</Link></li>
            
			
            <li><Link to={"/contact"}>Contact</Link></li>
			<li><Link to={"/profile"}>Profile</Link></li>
         </ul>

      </nav>

      

   </header>
    );
  }
}

export default Header;
