import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import '../App.css';
import '../styles/modalstyle.css';
import {onAuthStateChanged} from 'firebase/auth'
import { useNavigate,Link } from "react-router-dom";
import { auth} from "../config/Firebase";
import { Modal } from 'react-responsive-modal';

function Gigs() {
    const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  var uid;
  const navigate = useNavigate();
  

  

  useEffect(() => {
	  	  const loggedInUser = localStorage.getItem("loggedIn");
	if (loggedInUser) {
      const foundUser = loggedInUser;
	  onAuthStateChanged(auth, (user) => {
	if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	
	const url = '../retro/findgigsjson.php';
	uid  = auth?.currentUser?.uid;
	
	var formData = new FormData();
	formData.append("myUID", uid);
	formData.append("myLimit", 100);
	
	const config = { 'content-type': 'application/json' };
    const fetchPosts = async () => {
      await axios.post(url, formData, config)
	  .then(res => {const data =  res.data;
	  
	  //console.log(totalPosts);

      setPosts(data);});
    };

    fetchPosts();
	
	
    // ...
  } else {
    // User is signed out
    // ...
  }
});
    }
	else{
    navigate(
          "../",
          {
            replace: true,
          }
        );
	}
	  
  }, [navigate]); // Fetch the next page of posts when the current page changes


  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);


  return (
    <div className="container">
      <PostList posts={currentPosts} />
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );


	

  
}

const PostList = ({ posts }) => {
    const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  
  const dataOnePlayer = { playerCount: 1 };
  const dataTwoPlayer = { playerCount: 2 };
  const dataThreePlayer = { playerCount: 3 };
  const dataFourPlayer = { playerCount: 4 };
  
  return (
      <section id="rounds">
	  
	  
      <div className="row education">
         <div className="three columns header-col">
            <h2><span>Rounds</span></h2>
			
         </div>
		 
		<div>
      <button onClick={onOpenModal}>New Round</button>
      <Modal open={open} onClose={onCloseModal} center
		classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}>
        <h2>How many players?</h2>
		<button><Link to="/new" state={dataOnePlayer}>1</Link></button>
		<button><Link to="/new" state={dataTwoPlayer}>2</Link></button>
		<button><Link to="/new" state={dataThreePlayer}>3</Link></button>
		<button><Link to="/new" state={dataFourPlayer}>4</Link></button>
      </Modal>
	  
	  
    </div>

         <div className="nine columns main-col">
		 
	 
		 
            <div className="row item">
			
               { posts.map(post =>  
			   
			   <li  key={post.pid}>
                {(() => {
        if (post.pid.length > 1) {
          return (
		  
		  
		  

		  
		  
		  <div class="tg-wrap"><table class="tg">
		  
				
				<tbody>
				  <tr>
					
					<td class="tg-baqh">
					<p> {post.date.substring(8,10)}{post.date.substring(7,8)}{post.date.substring(5,7)}{post.date.substring(4,5)}{post.date.substring(0,4)}</p>
					</td>
					<td class="tg-baqh">
					<p><a className = "venue-name"  > {post.courseName}</a></p>
					</td>
					<td class="tg-baqh">
					<p><i className="fa fa-user fa-1x">&nbsp;</i>{post.countPlayersOnStart}</p>
					</td>
					<td class="tg-baqh">
					<a ><i className="fa fa-info-circle fa-1x">&nbsp;</i>
                <Link to={`/${post.pid}`}>Details</Link>
              </a>
			  </td>
			  <td class="tg-baqh">
					<a >
                <button class="deleteroundbutton">X</button>
              </a>
			  
			  </td>
				  </tr>
				</tbody>
				</table></div>
	 
       )
        }
		else if (post.pid.length < 1) {
          return (
            
		   <div class="tg-wrap"><table class="tg">

<tbody>
  <tr>
    <td class="tg-baqh">
					
					</td>
  </tr>
</tbody>
</table></div>
		  
		  
		  
          )
        } else {
          return (
            <div>catch all</div>
          )
        }
        })()}
            </li>
		 
      )
	  
     }
	 
            </div>
         </div>
      </div>

   </section>
    
  );
};

const Pagination = ({
  postsPerPage,
  totalPosts,
  setCurrentPage,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber, e) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <a
              onClick={(e) => paginate(number, e)}
              href="!#"
              className="page-link"
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Gigs;