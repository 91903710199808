import React, { useState, useEffect } from 'react';
import ScorecardEnterView from './ScorecardEnterView';
import testGolfClub from '../data/testGolfClub';
import Header from '../Components/Header';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { auth} from "../config/Firebase";
import {onAuthStateChanged} from 'firebase/auth'
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const ScorecardEnter = () => {
	const location = useLocation();
	const state = location.state;
	console.log(state);
	
	const navigate = useNavigate();
	const notifyError = () => toast.error('Invalid Input Found.',
  {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
    },
  }
);

const notifySuccess = () => toast.success('Round Added Successfully.',
  {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
    },
  }
);
	let [playerCount, setPlayerCount] = useState();
    let [courseData, setCourseData] = useState(testGolfClub);
	let [courseName, setCourseName] = useState();
    let [playerOneName, setPlayerOneName] = useState();
	let [playerTwoName, setPlayerTwoName] = useState();
	let [playerThreeName, setPlayerThreeName] = useState();
	let [playerFourName, setPlayerFourName] = useState();
    let [date, setDate] = useState((new Date().toISOString()))
	let playerOneScoresForMysqlNoQuotes = [];
	let playerTwoScoresForMysqlNoQuotes = [];
	let playerThreeScoresForMysqlNoQuotes = [];
	let playerFourScoresForMysqlNoQuotes = [];
	let playerOneScoresForStats = [];
	let playerTwoScoresForStats = [];
	let playerThreeScoresForStats = [];
	let playerFourScoresForStats = [];
	let parNumbersForMysqlNoQuotes = [];
	let parNumbersForStats = [];
	var myUID = auth?.currentUser?.uid;
	var countPlayersOnStart;
	var dateForMySQL;
	var timestamp;
	
	  useEffect(() => {
	  const loggedInUser = localStorage.getItem("loggedIn");
	if (loggedInUser) {
      const foundUser = loggedInUser;
	  onAuthStateChanged(auth, (user) => {
	if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	
	
    // ...
  } else {
    navigate(
          "../",
          {
            replace: true,
          }
        );
  }
});
    }
	else{
    navigate(
          "../",
          {
            replace: true,
          }
        );
	}
	  
	
  }, [navigate]);

    useEffect(() => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => {return {...hole, score: hole.score}} )
        })
    }, []);

	
	const updateCourseName = (courseNameUpdate) => {
        localStorage.setItem("courseName", courseNameUpdate);
    }
	
	const updatePlayerOneName = (playerOneNameUpdate) => {
        localStorage.setItem("playeronename", playerOneNameUpdate);
    }
	
	const updatePlayerTwoName = (playerTwoNameUpdate) => {
        localStorage.setItem("playertwoname", playerTwoNameUpdate);
    }
	
	const updatePlayerThreeName = (playerThreeNameUpdate) => {
        localStorage.setItem("playerthreename", playerThreeNameUpdate);
    }
	
	const updatePlayerFourName = (playerFourNameUpdate) => {
        localStorage.setItem("playerfourname", playerFourNameUpdate);
    }
	
	const updatePar = (holeIndex, par) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, par: par} : hole)
        });
	var parNumbers = JSON.parse(localStorage.getItem("parNumbers"));
    if(parNumbers == null) parNumbers = [];
    parNumbers[holeIndex] = par;
    localStorage.setItem("parNumbers", JSON.stringify(parNumbers));
    }

    const updateP1Score = (holeIndex, p1score) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, p1score: p1score} : hole)
        });
	var playerOne = JSON.parse(localStorage.getItem("playerOne"));
    if(playerOne == null) playerOne = [];
    playerOne[holeIndex] = p1score;
    localStorage.setItem("playerOne", JSON.stringify(playerOne));
    }
	
	const updateP2Score = (holeIndex, p2score) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, p2score: p2score} : hole)
        });
	var playerTwo = JSON.parse(localStorage.getItem("playerTwo"));
    if(playerTwo == null) playerTwo = [];
    playerTwo[holeIndex] = p2score;
    localStorage.setItem("playerTwo", JSON.stringify(playerTwo));
    }
	
	const updateP3Score = (holeIndex, p3score) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, p3score: p3score} : hole)
        });
	var playerThree = JSON.parse(localStorage.getItem("playerThree"));
    if(playerThree == null) playerThree = [];
    playerThree[holeIndex] = p3score;
    localStorage.setItem("playerThree", JSON.stringify(playerThree));
    }
	
	const updateP4Score = (holeIndex, p4score) => {
        setCourseData({
            ...courseData,
            holes: courseData.holes.map((hole, index) => holeIndex === index ? {...hole, p4score: p4score} : hole)
        });
	var playerFour = JSON.parse(localStorage.getItem("playerFour"));
    if(playerFour == null) playerFour = [];
    playerFour[holeIndex] = p4score;
    localStorage.setItem("playerFour", JSON.stringify(playerFour));
    }

    const onFocus = event => event.target.select();

    const  handleEnter = (event) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }
	
	const prepareNewRound = async () => {
			var playerNamesArray = [];
			var myUID = auth?.currentUser?.uid;
			if(parseInt(playerCount) > 0 && parseInt(playerCount) < 5 && playerCount.match(/^[0-9]+$/) && parseInt(playerCount) % 1 === 0 && Number.isInteger(parseInt(playerCount))){
			countPlayersOnStart = playerCount;
			
			}
			else{
					countPlayersOnStart = localStorage.getItem("playerCount");
				}
			//const courseName = localStorage.getItem("courseName");
			dateForMySQL = date.slice(0, 19).replace('T', ' ').split(' ')[0];
			timestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
			//playerone = localStorage.getItem("playeronename");
			playerNamesArray.push(courseName);
			playerNamesArray.push(playerOneName);
			if(countPlayersOnStart > 1){
				//playertwo = localStorage.getItem("playertwoname");
				playerNamesArray.push(playerTwoName);
				}
				else{
					//setPlayerTwoName("-");
					playerTwoName = "-";
				}
			if(countPlayersOnStart > 2){
				//playerthree = localStorage.getItem("playerthreename");
				playerNamesArray.push(playerThreeName);
				}
				else{
					playerThreeName = "-";
				}
			if(countPlayersOnStart > 3){
				//playerfour = localStorage.getItem("playerfourname");
				playerNamesArray.push(playerFourName);
				}
				else{
					playerFourName = "-";
				}
			
			const allArrays = [];
			const allArraysForMySQL = [];
			
			const parNumbersForMysql = localStorage.getItem("parNumbers");
			if(parNumbersForMysql !== null){
			parNumbersForMysqlNoQuotes = parNumbersForMysql.replace (/"/g,'');
			parNumbersForStats = parNumbersForMysqlNoQuotes.slice(1,-1).split(",");
			allArrays.push(parNumbersForStats);
			allArraysForMySQL.push(parNumbersForMysqlNoQuotes);
			}
			else{
			var parNumbersForMysqlNoQuotes = "[]";
			}
			
			console.log(parNumbersForMysql);
			console.log(parNumbersForMysqlNoQuotes);
			console.log(parseInt(parNumbersForStats[0]));
			
			const playerOneScoresForMysql = localStorage.getItem("playerOne");
			if(playerOneScoresForMysql !== null){
			playerOneScoresForMysqlNoQuotes = playerOneScoresForMysql.replace (/"/g,'');
			playerOneScoresForStats = playerOneScoresForMysqlNoQuotes.slice(1,-1).split(",");
			allArrays.push(playerOneScoresForStats);
			allArraysForMySQL.push(playerOneScoresForMysqlNoQuotes);
			}
			else{
			var playerOneScoresForMysqlNoQuotes = "[]";
			}
			
			if(countPlayersOnStart > 1){
			const playerTwoScoresForMysql = localStorage.getItem("playerTwo");
			if(playerTwoScoresForMysql !== null){
			playerTwoScoresForMysqlNoQuotes = playerTwoScoresForMysql.replace (/"/g,'');
			playerTwoScoresForStats = playerTwoScoresForMysqlNoQuotes.slice(1,-1).split(",");
			allArrays.push(playerTwoScoresForStats);
			allArraysForMySQL.push(playerTwoScoresForMysqlNoQuotes);
			}
			}
			else{
			var playerTwoScoresForMysqlNoQuotes = "[]";
			}
			
			if(countPlayersOnStart > 2){
			const playerThreeScoresForMysql = localStorage.getItem("playerThree");
			if(playerThreeScoresForMysql !== null){
			playerThreeScoresForMysqlNoQuotes = playerThreeScoresForMysql.replace (/"/g,'');
			playerThreeScoresForStats = playerThreeScoresForMysqlNoQuotes.slice(1,-1).split(",");
			allArrays.push(playerThreeScoresForStats);
			allArraysForMySQL.push(playerThreeScoresForMysqlNoQuotes);
			}
			}
			else{
			var playerThreeScoresForMysqlNoQuotes = "[]";
			}
			
			if(countPlayersOnStart > 3){
			const playerFourScoresForMysql = localStorage.getItem("playerFour");
			if(playerFourScoresForMysql !== null){
			playerFourScoresForMysqlNoQuotes = playerFourScoresForMysql.replace (/"/g,'');
			playerFourScoresForStats = playerFourScoresForMysqlNoQuotes.slice(1,-1).split(",");
			allArrays.push(playerFourScoresForStats);
			allArraysForMySQL.push(playerFourScoresForMysqlNoQuotes);
			}
			}
			else{
			var playerFourScoresForMysqlNoQuotes = "[]";
			}
			
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(dateForMySQL.match(regEx)){
				console.log("date ok");
			}
			else{
				console.log("date NOT ok");
			}
			
			if(timestamp.match(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/)){
				console.log("timestamp ok");
			}
			else{
				console.log("timestamp NOT ok");
			}
			
			const namesMap = playerNamesArray.flatMap(m => m);
			var nameArrayCount = 0;
			namesMap.some(function(rangeValue , i) {
			if(rangeValue.match(/[a-zA-Z]/g)){
			  console.log("name found");
			  nameArrayCount++;
				}
				else{
					console.log(rangeValue);
					console.log("NO name found");
				}
			});
			const arrMap = allArrays.flatMap(m => m);
			console.log(arrMap);
			var arrayCount = 0;
			
			arrMap.some(function(rangeValue , i) {
			if(parseInt(rangeValue) > 0 && parseInt(rangeValue) < 100 && rangeValue.match(/^[0-9]+$/) && parseInt(rangeValue) % 1 === 0 && Number.isInteger(parseInt(rangeValue))){
			  console.log("number found");
			  arrayCount++;
				}
				else{
					console.log(rangeValue);
					console.log("NO number found");
				}
			});
			
			const arrMapForMySQL = allArraysForMySQL.flatMap(m => m);
			console.log(arrMapForMySQL);
			var arrayCountForMySQL = 0;
			
			arrMapForMySQL.some(function(rangeValue , i) {
			if(rangeValue.match(/^\[[1-9][0-9]?(?:,\s*[1-9][0-9]?)*\]$/) &&
			rangeValue.match(/^(?:[^,]*,){17}[^,]*$/)){
			  console.log("valid number array found");
			  arrayCountForMySQL++;
				}
				else{
					console.log(rangeValue);
					console.log("valid number array NOT found");
				}
			});
			
			console.log("names "+nameArrayCount);
			console.log("numbers "+arrayCount);
			console.log("numbersARRAY "+arrayCountForMySQL);
		
		if(dateForMySQL.match(/^\d{4}-\d{2}-\d{2}$/) && 
		timestamp.match(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/) && 
		myUID && 
		nameArrayCount === (parseInt(countPlayersOnStart) + 1) && 
		arrayCount === (18 + 18 * parseInt(countPlayersOnStart)) && 
		arrayCountForMySQL === (parseInt(countPlayersOnStart) + 1)){
			const url = '../retro/create_round.php';
			var formData = new FormData();
			formData.append("myUID", myUID);
			formData.append("countPlayersOnStart", countPlayersOnStart);
			formData.append("courseName", courseName);
			formData.append("date", dateForMySQL);
			formData.append("timestamp", timestamp);
			formData.append("playerone", playerOneName);
			formData.append("playertwo", playerTwoName);
			formData.append("playerthree", playerThreeName);
			formData.append("playerfour", playerFourName);
			formData.append("parNumbersForMysql", parNumbersForMysqlNoQuotes);
			formData.append("playerOneScoresForMysql", playerOneScoresForMysqlNoQuotes);
			formData.append("playerTwoScoresForMysql", playerTwoScoresForMysqlNoQuotes);
			formData.append("playerThreeScoresForMysql", playerThreeScoresForMysqlNoQuotes);
			formData.append("playerFourScoresForMysql", playerFourScoresForMysqlNoQuotes);
			
			const config = { 'content-type': 'application/json' };
			axios.post(url, formData, config)
			.then(response => {
				
			countSets();
        })
					//console.log(playerNamesArray[i]);
					notifySuccess();
					console.log("error not found round added");
					
			
		}
		else{
			notifyError();
			console.log("array size error found");
		}
			
			
	}
	
	const countSets = async () => {
		var myUID = auth?.currentUser?.uid;
		const url = '../retro/count_sets.php';
	
		var formData = new FormData();
		formData.append("myUID", myUID);
	
		const config = { 'content-type': 'application/json' };
		axios.post(url, formData, config)
		.then(response => {const data =  response.data;
		
		addStats(data.data[0].fullsets);
	  
		console.log(data.data[0].fullsets);
        })
	}
	
	const addStats = async (setsCount) => {
		var myUID = auth?.currentUser?.uid;
		let holesinone = 0,albatross = 0,eagles = 0,birdies = 0,pars = 0,bogeys = 0,doublebogies = 0,triplebogies = 0,worsethantriplebogies = 0;
		    for (let i = 0; i <= playerOneScoresForStats.length - 1; i++) {
            if (playerOneScoresForStats[i] == 1) {
                holesinone++;
            } else if (playerOneScoresForStats[i] > 1) {

                if (parNumbersForStats[i] == playerOneScoresForStats[i]) {
                    pars++;
                } else if (parNumbersForStats[i] > playerOneScoresForStats[i]) {
                    let x = Math.abs(parNumbersForStats[i] - playerOneScoresForStats[i]);
                    if (x == 1) {
                        birdies++;
                    } else if (x == 2) {
                        eagles++;
                    } else if (x == 3) {
                        albatross++;
                    } else if (x > 3) {
                        worsethantriplebogies++;
                    }
                } else if (playerOneScoresForStats[i] > parNumbersForStats[i]) {
                    let x = Math.abs(playerOneScoresForStats[i] - parNumbersForStats[i]);
                    if (x == 1) {
                        bogeys++;
                    } else if (x == 2) {
                        doublebogies++;
                    } else if (x == 3) {
                        triplebogies++;
                    } else if (x > 3) {
                        worsethantriplebogies++;
                    }
                }
            }
        }
			
			const url = '../retro/create_stats.php';
			var formData = new FormData();
			formData.append("sets", setsCount);
			formData.append("myUID", myUID);
			formData.append("holesinone", holesinone);
			formData.append("albatross", albatross);
			formData.append("eagles", eagles);
			formData.append("birdies", birdies);
			formData.append("pars", pars);
			formData.append("bogeys", bogeys);
			formData.append("doublebogies", doublebogies);
			formData.append("triplebogies", triplebogies);
			formData.append("worsethantriplebogies", worsethantriplebogies);
			
			const config = { 'content-type': 'application/json' };
			axios.post(url, formData, config)
			.then(response => {
				
			
        })
		console.log(holesinone);
	}

    return(
	<>
	<Header />
        <ScorecardEnterView 
			playerCount={playerCount}
			setPlayerCount={setPlayerCount}
            courseData={courseData}
			courseName={courseName}
            setCourseName={setCourseName}
            playerOneName={playerOneName}
            setPlayerOneName={setPlayerOneName}
			playerTwoName={playerTwoName}
            setPlayerTwoName={setPlayerTwoName}
			playerThreeName={playerThreeName}
            setPlayerThreeName={setPlayerThreeName}
			playerFourName={playerFourName}
            setPlayerFourName={setPlayerFourName}
            date={date}
            setDate={setDate}
			updateCourseName={updateCourseName}
			updatePlayerOneName={updatePlayerOneName}
			updatePlayerTwoName={updatePlayerTwoName}
			updatePlayerThreeName={updatePlayerThreeName}
			updatePlayerFourName={updatePlayerFourName}
			updatePar={updatePar}
            updateP1Score={updateP1Score}
			updateP2Score={updateP2Score}
			updateP3Score={updateP3Score}
			updateP4Score={updateP4Score}
            onFocus={onFocus}
            handleEnter={handleEnter}
        />
		<div>
      <button onClick={prepareNewRound}>Add Round</button>
	  <Toaster />
    </div>
		</>
    );

};

export default ScorecardEnter;