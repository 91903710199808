import React, { useEffect } from 'react';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import '../styles/scorecard.css';
import { useLocation } from "react-router-dom";


const ScorecardEnterView = ({courseData, playerCount,setPlayerCount, courseName,setCourseName, playerOneName, setPlayerOneName, playerTwoName, setPlayerTwoName, playerThreeName, setPlayerThreeName, playerFourName, setPlayerFourName, date, setDate, updateCourseName, updatePlayerOneName, updatePlayerTwoName, updatePlayerThreeName, updatePlayerFourName, updatePar, updateP1Score, updateP2Score, updateP3Score, updateP4Score, onFocus, handleEnter}) => {
	const location = useLocation();
	const state = location.state;
	console.log(state);
	var regexLocalStorage = /^\["[1-9][0-9]?"(?:,\s*"[1-9][0-9]?")*\]$/;
	if(state !== null){
		localStorage.setItem("playerCount", state.playerCount);
		playerCount = state.playerCount;}
		else{
		playerCount = localStorage.getItem("playerCount");
		}
	
		if(JSON.parse(localStorage.getItem("parNumbers")) === null){
		var parNumbers = [];
		}else{
		var parNumbers = JSON.parse(localStorage.getItem("parNumbers"));
		}
		
		if(JSON.parse(localStorage.getItem("playerOne")) === null){
		var playerOne = [];
		}else{
		var playerOne = JSON.parse(localStorage.getItem("playerOne"));
		}
		
		if(JSON.parse(localStorage.getItem("playerTwo")) === null){
		var playerTwo = [];
		}else{
		var playerTwo = JSON.parse(localStorage.getItem("playerTwo"));
		}
		
		if(JSON.parse(localStorage.getItem("playerThree")) === null){
		var playerThree = [];
		}else{
		var playerThree = JSON.parse(localStorage.getItem("playerThree"));
		}
		
		if(JSON.parse(localStorage.getItem("playerFour")) === null){
		var playerFour = [];
		}else{
		var playerFour = JSON.parse(localStorage.getItem("playerFour"));
		}
		
		console.log(parNumbers);
	
	useEffect(() => {
	
    if(localStorage.getItem("roundDate") === null){
		localStorage.setItem("roundDate", date);
		setDate(date);
	}else{
		var checkDateFormat = localStorage.getItem("roundDate");
		if(checkDateFormat.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/)){
		setDate(localStorage.getItem("roundDate"));
		}
	}
	
	if(localStorage.getItem("courseName") === null){
		localStorage.setItem("courseName", "My Course");
		setCourseName("My Course");
	}else{
		setCourseName(localStorage.getItem("courseName"));
	}
	
	if(localStorage.getItem("playeronename") === null){
		localStorage.setItem("playeronename", "p1");
		setPlayerOneName("p1");
	}else{
		setPlayerOneName(localStorage.getItem("playeronename"));
	}
	
	if(localStorage.getItem("playertwoname") === null){
		localStorage.setItem("playertwoname", "p2");
		setPlayerTwoName("p2");
	}else{
		setPlayerTwoName(localStorage.getItem("playertwoname"));
	}
	
	if(localStorage.getItem("playerthreename") === null){
		localStorage.setItem("playerthreename", "p3");
		setPlayerThreeName("p3");
	}else{
		setPlayerThreeName(localStorage.getItem("playerthreename"));
	}
	
	if(localStorage.getItem("playerfourname") === null){
		localStorage.setItem("playerfourname", "p4");
		setPlayerFourName("p4");
	}else{
		setPlayerFourName(localStorage.getItem("playerfourname"));
	}
      console.log('mount');
    }, []);
	
	useEffect(() => {
      localStorage.setItem("roundDate", date);
	}, [date]);
	
    const DatePickerInput = ({ value, onClick }) => (
        <span onClick={onClick}>
            {value}
        </span>
    )
    
    return(
        <form>
		<div className="scoreviewouter">
            <div className="scoreview">
            <div className="nine">
                <div className="line">
				<span className="date">
                        <DatePicker
                            selected={date}
                            onChange={newDate => setDate(newDate.toISOString())}
                            customInput={<DatePickerInput />}
                            withPortal
                        />
                    </span>
                 <span className="coursename">
                        <input onKeyDown={handleEnter} onFocus={onFocus} value={courseName} placeholder="Course" className="course-box" onChange={(event) => {
							setCourseName(event.target.value);
							updateCourseName(event.target.value);
							}
						}/>
                    </span>
                </div>
				
                <div className="line">
                    <span className="hole">Hole</span>
                    <span className="par">Par</span>
                    <span className="par">
						<input onKeyDown={handleEnter} onFocus={onFocus} value={playerOneName} placeholder="P1" className="par-box" onChange={(event) => {
							setPlayerOneName(event.target.value);
							updatePlayerOneName(event.target.value);
							}
						}/>
						</span>
						
						
					<span className="par">
					{playerCount > 1 ? (
					
						<input onKeyDown={handleEnter} onFocus={onFocus} value={playerTwoName} placeholder="P2" className="par-box" onChange={(event) => {
							setPlayerTwoName(event.target.value);
							updatePlayerTwoName(event.target.value);
							}
							}/>
						
					) : (
                <span className="par">-</span>
            )}
						</span>
						
						
					<span className="par">
					{playerCount > 2 ? (
						<input onKeyDown={handleEnter} onFocus={onFocus} value={playerThreeName} placeholder="P3" className="par-box" onChange={(event) => {
							setPlayerThreeName(event.target.value);
							updatePlayerThreeName(event.target.value);
							}
							}/>
						) : (
                <span className="par">-</span>
            )}
						</span>
						
						
					<span className="par">
					{playerCount > 3 ? (
						<input onKeyDown={handleEnter} onFocus={onFocus} value={playerFourName} placeholder="P4" className="par-box" onChange={(event) => {
							setPlayerFourName(event.target.value);
							updatePlayerFourName(event.target.value);
							}
							}/>
						) : (
                <span className="par">-</span>
            )}
						</span>
                </div>

                {courseData.holes.map((hole, index) => 
                    <div className="line" key={hole.hole} >
                        <span className="hole">{hole.hole}</span>
                        <span className="par">
							<input onKeyDown={handleEnter} onFocus={onFocus} value={hole.par ? hole.par : parNumbers[index]} type="number" min="1" max="9" className="par-box" onChange={(event) => 
							updatePar((hole.hole - 1), event.target.value)}/>
						</span>
                        <span className="score">
                            <input onKeyDown={handleEnter} onFocus={onFocus} value={hole.p1score ? hole.p1score : playerOne[index]} type="number" className="score-box" onChange={(event) => updateP1Score((hole.hole - 1), event.target.value)}/>
                        </span>
						<span className="score">
						{playerCount > 1 ? (
                            <input onKeyDown={handleEnter} onFocus={onFocus} value={hole.p2score ? hole.p2score : playerTwo[index]} type="number" className="score-box" onChange={(event) => updateP2Score((hole.hole - 1), event.target.value)}/>
                        ) : (
                <span className="score">-</span>
						)}
						</span>
						<span className="score">
						{playerCount > 2 ? (
                            <input onKeyDown={handleEnter} onFocus={onFocus} value={hole.p3score ? hole.p3score : playerThree[index]} type="number" className="score-box" onChange={(event) => updateP3Score((hole.hole - 1), event.target.value)}/>
                        ) : (
                <span className="score">-</span>
						)}
						</span>
						<span className="score">
						{playerCount > 3 ? (
                            <input onKeyDown={handleEnter} onFocus={onFocus} value={hole.p4score ? hole.p4score : playerFour[index]} type="number" className="score-box" onChange={(event) => updateP4Score((hole.hole - 1), event.target.value)}/>
                        ) : (
                <span className="score">-</span>
						)}
						</span>
                    </div>    
                )}
               
            </div>
			</div>
			</div>
        </form>
    );
};


export default ScorecardEnterView;